import { useEffect, useState } from 'react';
import Head from 'next/head';
import { NextComponentType, NextPage } from 'next';

// Dev note: Use the window current url to get the current path
// const url = process.env.API_URL;
// const url = process?.title === 'browser' ? window.location.origin : process.env.APP_URL;

interface Props {
    title: string;
    description?: string;
    imageUrl?: string;
    imageAlt?: string;
    keywords?: string;
    canonicalUrl?: string | undefined | null;
}

const MetaDecorator: NextPage<Props> = ({
    title,
    description,
    imageUrl,
    imageAlt,
    keywords,
    canonicalUrl,
}) => {
    const [path, setPath] = useState({
        pathName: '',
        search: '',
    });
    const [url, setUrl] = useState(
        canonicalUrl ? canonicalUrl : process.env.APP_URL
    );

    useEffect(() => {
        if (typeof window !== undefined) {
            setPath({
                pathName: window.location.pathname,
                search: window.location.search,
            });
            setUrl(window.location.href);
        }
    }, []);

    return (
        <Head>
            <title>{title}</title>
            <link rel="icon" href="/favicon.ico" />
            <meta name="description" content={description} />
            <meta property="og:title" content={title} key="ogtitle" />
            <meta
                property="og:description"
                content={description}
                key="ogdesc"
            />
            <meta
                property="og:image:secure_url"
                content={imageUrl}
                key="ogimage"
            />
            <meta property="og:image:alt" content={imageAlt} />
            <meta property="og:url" content={url} key="ogurl" />
            <meta
                property="og:site_name"
                content="virtual-gravity"
                key="virtual-gravity"
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image:alt" content={imageAlt} />
            <meta name="keywords" content={keywords} />

            <meta name="twitter:site" content="Bot" />
            {(canonicalUrl || url) && <link rel="canonical" href={canonicalUrl || url} />}
            <script
                src="https://www.dwin1.com/34663.js"
                type="text/javascript"
                defer={true}
            ></script>
        </Head>
    );
};

export default MetaDecorator;
